// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useHistory, useLocation } from 'react-router-dom';
// import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
// import { slugs } from "../../../../Constant/slugs";
// import { AlertComponent } from "../../../AlertComponent";
// import { CustomButton } from "../../../CustomButtom";
// import ImageUpload from "../../../ImageUpload";
// import { login } from '../../../../redux/action';
// const studentRoles = ["subscriber", "student"]
// export default function FormComponent(props) {
//   const { FetchData } = props;
//   const history = useHistory()
//   const [ID, setID] = React.useState(FetchData.id)
//   const Login = useSelector((state) => state.LoginState);

//   const [author, setAuthor] = React.useState(FetchData.author)
//   const [Title, setTitle] = React.useState(FetchData.title.rendered)
//   const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
//   const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
//   const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
//   const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
//   const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
//   const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
//   const [userID, setUserID] = React.useState(FetchData.meta.user_id)
//   const [userName, setUserName] = React.useState(FetchData.meta.user_name)
//   const [Password, setPassword] = React.useState("")
//   const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
//   const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)

//   const [verifiedExamList, setVerifiedExamList] = React.useState(
//     FetchData.meta.verified_exam_list == "" ? [] :
//       JSON.parse(FetchData.meta.verified_exam_list)
//   )
//   const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
//   const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
//   const [BatchExam, setBatchExam] = React.useState(
//     FetchData.meta.verified_exam_list == "" ? [] :
//       JSON.parse(FetchData.meta.verified_exam_list))
//   const [BatchExamCache, setBatchExamCache] = React.useState(
//     FetchData.meta.verified_exam_list == "" ? [] :
//       JSON.parse(FetchData.meta.verified_exam_list))

//   const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
//   const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
//   const [batchModal, setBatchModal] = React.useState(false)
//   const [batchList, setBatchList] = React.useState([])
//   const [AddNew, setAddNew] = React.useState(false)
//   const [serachName, setSearchName] = React.useState("")
//   const [ExamArray, setExamArray] = React.useState([])
//   const [ExamArray1, setExamArray1] = React.useState([])

//   const [AddNewBook, setAddNewBook] = React.useState(false)
//   const [serachBookName, setSearchBookName] = React.useState("")
//   const [BookArray, setBookArray] = React.useState([])
//   const [BookArray1, setBookArray1] = React.useState([])
//   const [VerifiedBook, setVerifiedBook] = React.useState(
//     FetchData.meta.verified_book_list == "" ? [] :
//       JSON.parse(FetchData.meta.verified_book_list))
//   const [VerifiedBookCache, setVerifiedBookCache] = React.useState(
//     FetchData.meta.verified_book_list == "" ? [] :
//       JSON.parse(FetchData.meta.verified_book_list))
//   const [BookDeleteIndex, setBookIndex] = React.useState("")
//   const [BookDeleteModal, setBookDeleteModal] = React.useState(false)

//   const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
//   const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
//   const [EditScreenShot, setEditScreenShot] = React.useState("")
//   const [EditStartDate, setEditStartDate] = React.useState("")
//   const [EditEndDate, setEditEndDate] = React.useState("")
//   const [EditAmountPaid, setEditAmountPaid] = React.useState("")
//   const [EditRemarks1, setEditRemarks1] = React.useState("")
//   const [EditRemarks2, setEditRemarks2] = React.useState("")
//   const [subscriptionTime, setSubscriptionTime] = React.useState("")
//   const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)
//   const [VerifyTime, setTime] = React.useState("")

//   // const [Name, setName] = React.useState(FetchData.title.rendered)
//   const dispatch = useDispatch()

//   const [reload, setReload] = React.useState(false)

//   const [AlertShow, setAlertShow] = React.useState(false)
//   const [Alertcomp, setAlertcomp] = React.useState("")
//   const [UBTC, setUBTC] = React.useState(0)

//   const AfterUserCreate = (id) => {
//     const data = {
//       "title": Title,
//       "status": "publish",
//       "author": id,
//       "meta": {
//         "user_role": UserRole,
//         "user_profile_picture": ProfilePicture,
//         "user_subscription_start": SubscriptionStart,
//         "user_subscription_end": SubscriptionEnd,
//         "user_subscription_time": subscriptionTime,
//         "user_phone_number": PhoneNumber,
//         "verification_status": VerificationStatus,
//         "verified_exam_list": JSON.stringify(BatchExam),
//         "verified_book_list": JSON.stringify(VerifiedBook),
//         "user_id": `${id}`,
//         "user_name": userName,
//         "user_email": userEmail,
//         "user_verification_history": userVerificationHistory,
//         "batch_id": batchID,
//         "batch_name": batchName
//       }
//     }
//     if (ID === "")
//       AdsPOSTApi(data, slugs.db_slug_user_data)
//         .then((res) => {
//           setReload(false)
//           setID(res.data.id)
//           setAlertShow(true)
//           setAlertcomp("Book Added Successfully")
//           history.push(`${slugs.student_details}${res.data.slug}`)
//         }).catch(err => {
//           setReload(false)
//           console.log(err)
//           setAlertShow(true)
//           setAlertcomp(err.response.data.message)
//         })
//     else
//       AdsPUTApi(data, slugs.db_slug_user_data, ID)
//         .then((res) => {
//           setReload(false)
//           setAlertShow(true)
//           setAlertcomp(`${Title} edited Successfully`)
//         }).catch(err => {
//           console.log(err)
//           console.warn(err.response.data)
//           console.log("Response Status: hello11", err.response.status);
//           console.log("Response Headers:", err.response.headers);
//           setReload(false)
//           setAlertShow(true)
//           setAlertcomp(err.response.data.message)
//         })
//   }
//   const EditInfo = () => {
//     setReload(true)

//     if (ID == "") {
//       const dataWithPassword = {
//         "username": userName,
//         "email": userEmail,
//         "password": Password,
//         "roles": UserRole
//       }
//       AdsPOSTApi(dataWithPassword, "users")
//         .then(res => {
//           AfterUserCreate(res.data.id)
//         })
//         .catch(e =>
//           console.log(e)
//         )
//     }
//     else {
//       if (Datapassword) {
//         const dataWithPassword = {
//           "password": Password,
//           "roles": UserRole
//         }
//         if (Login.userID !== userID)
//           AdsPUTApi(dataWithPassword, `users`, `${userID}`)
//             .then(res => {
//               AfterUserCreate(userID)
//             })
//             .catch(e =>
//               console.log(e)
//             )
//         else {
//           setAlertShow(true)
//           setAlertcomp("You are not allowed to cahnge owns password")
//           setReload(false)

//           // dispatch(login(
//           //   `${Login.id}`,
//           //   `${Login.userName}`,
//           //   `${window.btoa(`${userName}:${Password}`)}`,
//           //   `${Login.userEmail}`,
//           //   `${Login.userRole}`,
//           //   `${Login.userPhoto}`,
//           //   `${Login.userPhoneNumber}`,
//           //   `${Login.userBatch}`,
//           // ))
//           // AdsPUTApi(dataWithPassword, `users`, `${userID}`)
//           //   .then(res => {
//           //     AfterUserCreate(userID)
//           //   })
//           //   .catch(e =>
//           //     console.log(e)
//           //   )
//         }
//       }
//       else {
//         const data = {
//           "roles": UserRole
//         }
//         AdsPUTApi(data, `users`, `${userID}`)
//           .then(res => {
//             AfterUserCreate(userID)
//           })
//           .catch(e =>
//             console.log(e)
//           )
//       }
//     }
//   }

//   const dateChangePressed = (verifyTime) => {
//     const date = new Date();

//     const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
//     const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

//     const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
//     const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
//     const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
//     const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
//     setEditStartDate(`${yearToday}-${monthToday}-${day}`)
//     setEditEndDate(`${year}-${month}-${day}`)

//   }

//   useEffect(() => {
//     // BatchList()
//     FetchExamList()
//     FetchBookList()
//   }, [])
//   const BatchList = () => {
//     AdsGETApiAuth(
//       { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
//       slugs.db_slug_batch
//     )
//       .then((res) => {
//         setBatchList(res.data)
//       })
//       .catch(e => {
//         console.log(e)
//       })
//   }

//   const FetchExamList = () => {
//     AdsGETApiAuth(
//       { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.exam_type" },
//       slugs.db_slug_buy_exam
//     )
//       .then((res) => {
//         setExamArray1(res.data)
//         setExamArray(res.data.filter(a => a.meta.exam_type == "UBT"))
//       })
//       .catch(e => {
//         console.log(e)
//       })
//   }
//   const FetchBookList = () => {
//     AdsGETApiAuth(
//       { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.exam_type" },
//       slugs.db_slug_book
//     )
//       .then((res) => {
//         setBookArray1(res.data)
//         setBookArray(res.data)
//       })
//       .catch(e => {
//         console.log(e)
//       })
//   }

//   const MappingExam = ({ item }) => {
//     const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
//     const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
//     const clickFunction = () => {
//       if (!CheckON)
//         setCheckON(true)
//       else
//         setCheckON(false)
//       if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
//         BatchExamCache.push({
//           exam_id: item.id,
//           featured_image: item.meta.featured_image,
//           name: item.title.rendered,
//           exam_type: item.meta.exam_type,
//           slug: item.slug
//         })
//         // BatchExamIDCache.push(item.id)
//       }
//     }
//     return (
//       <>
//         <td onClick={() => { clickFunction() }}>
//           {CheckON ?
//             <i className="fa fa-check-square-o" />
//             :
//             <i className="fa fa-square-o" />
//           }
//         </td>
//         <td onClick={() => { clickFunction() }}>{item.id}</td>
//         <td onClick={() => { clickFunction() }}>{item.title.rendered}</td>
//         {/* <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}
//         >Choose</td> */}
//       </>
//     )
//   }

//   const MappingBook = ({ item }) => {
//     const EnrollmentSearch = Object.values(VerifiedBookCache).filter(a => a.exam_id == item.id)
//     const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
//     const clickFunction = () => {

//       setCheckON(true)
//       if (VerifiedBookCache.filter(a => a.exam_id == item.id).length == 0) {
//         VerifiedBookCache.push({
//           book_id: item.id,
//           name: item.title.rendered,
//           slug: item.slug
//         })
//       }
//     }
//     return (
//       <>
//         <td onClick={() => { clickFunction() }}>
//           {CheckON ?
//             <i className="fa fa-check-square-o" />
//             :
//             <i className="fa fa-square-o" />
//           }
//         </td>
//         <td onClick={() => { clickFunction() }}>{item.id}</td>
//         <td onClick={() => { clickFunction() }}>{item.title.rendered}</td>
//         <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
//       </>
//     )
//   }

//   return (
//     <div>
//       <div className="row">
//         <div className="col-sm-2 col-1"></div>
//         <div className="col-sm-8 col-10 form-view mb-3">
//           <h4 style={{ backgroundColor: "#ccc", fontWeight: "bold", color: "#000", padding: 5 }}><center>Student Information</center></h4>
//           {/* <h4><center>Student Information </center></h4> */}
//           <table>
//             <tbody>
//               <tr>
//                 <td className="col-3"><b>Student Name</b> </td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={Title} placeholder="Name"
//                     onChange={(event) => { setTitle(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3"><b>Student Role</b></td>
//                 <td className="col-6">
//                   <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
//                   <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} />
//                   <CustomButton setData={setUserRole} Data={UserRole} DataValue={"teacher"} />
//                   <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} />
//                 </td>
//               </tr>
//               {/* <tr>
//                 <td className="col-3"><b>Verification Status</b></td>
//                 <td className="col-6">
//                   <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
//                   <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
//                 </td>
//               </tr> */}
//               {/* <tr>
//                 <td className="col-3">Subscription Start</td>
//                 <td className="col-6">
//                   <input className="input-common" type="date"
//                     value={SubscriptionStart} placeholder="Subscription Start"
//                     onChange={(event) => { setSubscriptionStart(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Subscription End</td>
//                 <td className="col-6">
//                   <input className="input-common" type="date"
//                     value={SubscriptionEnd} placeholder="Subscription End"
//                     onChange={(event) => { setSubscriptionEnd(event.target.value) }}
//                   />
//                 </td>
//               </tr> */}
//               <tr>
//                 <td className="col-3"><b>Profile Picture</b></td>
//                 <td className="col-6">
//                   <ImageUpload
//                     ImageUrl={ProfilePicture}
//                     setImageUrl={setProfilePicture}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3"><b>Phone Number</b></td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={PhoneNumber} placeholder="Phone Number"
//                     onChange={(event) => { setPhoneNumber(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3"><b>User Name</b></td>
//                 <td className="col-6">
//                   {ID == "" ?
//                     <input className="input-common"
//                       value={userName} placeholder="User Name"
//                       onChange={(event) => { setUserName(event.target.value) }}
//                     />
//                     :
//                     <div className="input-common" >{userName}</div>
//                   }
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3"><b>User Email</b></td>
//                 <td className="col-6">
//                   {ID == "" ?
//                     <input className="input-common"
//                       value={userEmail} placeholder="User Email"
//                       onChange={(event) => { setUserEmail(event.target.value) }}
//                     />
//                     :
//                     <div className="input-common" >{userEmail}</div>
//                   }
//                 </td>
//               </tr>
//               {/* <tr>
//                 <td className="col-3">User ID</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={userID} placeholder="User ID"
//                     onChange={(event) => { setUserID(event.target.value) }}
//                   />
//                 </td>
//               </tr> */}
//               <tr>
//                 <td className="col-3"><b>Password</b></td>
//                 <td className="col-6">
//                   {Datapassword ?
//                     <input className="input-common"
//                       value={Password} placeholder="Password"
//                       onChange={(event) => { setPassword(event.target.value) }}
//                     />
//                     :
//                     <div>
//                       <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
//                         onClick={() => {
//                           setDataPassword(true)
//                           setPassword("")
//                         }}
//                       >
//                         <div className={"passive-option"}>
//                           Change Password
//                         </div>
//                       </button>
//                     </div>
//                   }
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//           {Login.userRole == "author" &&
//             <div className="row center m-2">
//               <div className=" col-3" style={{ padding: "0 10px" }}>
//                 <div className="sign-in-button-4" style={{ backgroundColor: "#108f04", color: "#fff", fontWeight: "bold" }}
//                   onClick={() => {
//                     EditInfo()
//                   }}
//                 >
//                   Update Student
//                 </div>
//               </div>
//             </div>
//           }
//         </div>
//       </div>

//       {Login.userRole == "author" &&
//         <div className="row">
//           <div className="col-sm-2 col-1"></div>
//           <div className="col-sm-8 col-10 form-view mb-3">
//             <h4 style={{ backgroundColor: "#ccc", fontWeight: "bold", color: "#000", padding: 5 }}><center>Verified Exam List</center></h4>
//             <table>
//               <tbody>
//                 <tr style={{  fontWeight: "bold",  padding: 5 }}>
//                   <td>Exam ID</td>
//                   <td>Exam Name</td>
//                   <td>Exam Type</td>
//                 </tr>

//                 {BatchExam.map((item, index) => (
//                   <tr key={index}>
//                     <td>{item.exam_id}</td>
//                     <td>{item.name}</td>
//                     <td style={{ cursor: "pointer" }} onClick={() => {
//                       setBatchExamIndex(index)
//                       setBatchExamDeleteModal(true)
//                       // BatchExam.splice(index, 1)
//                     }}>Delete</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//             <div className="row center m-2">
//               <div className=" col-3" style={{ padding: "0 10px" }}>
//                 <div style={{ cursor: "pointer" }} onClick={() => {
//                   setAddNew(true)
//                 }}>
//                   <div className="sign-in-button-4" style={{ backgroundColor: "#156cf2", color: "#fff", fontWeight: "bold" }}
//                     onClick={() => {
//                     }}
//                   >
//                     Add New
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       }
//       {Login.userRole == "author" &&
//         <div className="row">
//           <div className="col-sm-2 col-1"></div>
//           <div className="col-sm-8 col-10 form-view mb-3">
//             <h4 style={{ backgroundColor: "#ccc", fontWeight: "bold", color: "#000", padding: 5 }}><center>Verified Book List</center></h4>
//             <table>
//               <tbody>
//                 <tr style={{ fontWeight: "bold",  padding: 5 }}>
//                   <td>Book ID</td>
//                   <td>Book Name</td>
//                 </tr>
//                 {VerifiedBook.map((item, index) => (
//                   <tr key={index}>
//                     <td>{item.book_id}</td>
//                     <td>{item.name}</td>
//                     <td style={{ cursor: "pointer" }} onClick={() => {
//                       setBookIndex(index)
//                       setBookDeleteModal(true)
//                     }}>Delete</td>
//                   </tr>
//                 ))}
//                 <tr>

//                 </tr>
//               </tbody>
//             </table>
//             <div className="row center m-2">
//               <div className=" col-3" style={{ padding: "0 10px" }}>
//                 <div style={{ cursor: "pointer" }} onClick={() => {
//                   setAddNewBook(true)
//                 }}>
//                   <div className="sign-in-button-4" style={{ backgroundColor: "#156cf2", color: "#fff", fontWeight: "bold" }}
//                     onClick={() => {
//                     }}
//                   >
//                     Add New
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       }
//       {BatchExamDeleteModal &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setBatchExamDeleteModal(false)
//                   }}
//                 >X</div>
//                 Do you really want to delete Exam?
//                 <table>
//                   <tbody>
//                     <tr>
//                       <td>Exam Name : </td>
//                       <td>{BatchExam[BatchExamDeleteIndex].name}</td>
//                     </tr>
//                     <tr>
//                       <td>Exam ID : </td>
//                       <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="row" >
//                   <div className="col-1 center " />
//                   <div className="col-5 center " >
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         setBatchExamDeleteModal(false)
//                       }}
//                     >
//                       Cancel
//                     </div>
//                   </div>
//                   <div className="col-5 center">
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         BatchExam.splice(BatchExamDeleteIndex, 1)
//                         // BatchExamID.splice(BatchExamDeleteIndex, 1)
//                         setBatchExamDeleteModal(false)
//                       }}
//                     >
//                       OK
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       }

//       {AddNew &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
//                 {/* <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setAddNew(false)
//                   }}
//                 >X</div> */}
//                 {/* <div className="col-md-6 row">
//                   <div className="col-1" />
//                   <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
//                     value={serachName} placeholder="Name"
//                     onChange={(event) => {
//                       setSearchName(event.target.value)
//                       setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
//                     }}
//                   />
//                 </div> */}
//                 <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
//                   <input className="col-sm-3 col-11" style={{ margin: "1px 2px", padding: "3px 2px" }}
//                     value={serachName} placeholder="Name"
//                     onChange={(event) => {
//                       setSearchName(event.target.value)
//                       setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
//                     }}
//                   />
//                   <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
//                     onClick={() => {
//                       setUBTC(0)
//                       setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT"))
//                     }}
//                   >
//                     UBT
//                   </div>

//                   <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
//                     setUBTC(1)
//                     setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
//                   }} >
//                     ChapterWise
//                   </div>
//                 </div>

//                 <div style={{ height: "400px", overflowY: "scroll" }}>
//                   <table>
//                     <tbody>
//                       <tr>
//                         <td></td>
//                         <td>Exam ID</td>
//                         <td>Exam Name</td>
//                         {/* <td>Choose</td> */}
//                       </tr>
//                       {ExamArray.map((item, index) => (
//                         <tr key={index}>
//                           <MappingExam item={item} />
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div style={{ padding: "10px" }}>
//                   <div className="row">
//                     <div className="col-sm-1" />
//                     <div className="col-sm-5 col-12 center " >
//                       <div className="sign-in-button-4" style={{ width: "100%", backgroundColor: "#8f0404", color: "#fff", fontWeight: "bold" }}
//                         onClick={() => {
//                           setBatchExamCache(BatchExam)
//                           // setBatchExamIDCache(BatchExamID)
//                           setAddNew(false)
//                         }}
//                       >
//                         Cancel
//                       </div>
//                     </div>
//                     <div className="col-sm-5 col-12 center">
//                       <div className="sign-in-button-4" style={{ width: "100%", backgroundColor: "#108f04", color: "#fff", fontWeight: "bold" }}
//                         onClick={() => {
//                           setBatchExam(BatchExamCache)
//                           // setBatchExamID(BatchExamIDCache)
//                           setAddNew(false)
//                         }}
//                       >
//                         Done
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>


//             </div>
//           </div>
//         </section>
//       }

//       {BookDeleteModal &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setBookDeleteModal(false)
//                   }}
//                 >X</div>
//                 Do you really want to delete Book for the user?
//                 <table>
//                   <tbody>
//                     <tr>
//                       <td>Book Name : </td>
//                       <td>{VerifiedBook[BookDeleteIndex].name}</td>
//                     </tr>
//                     <tr>
//                       <td>Exam ID : </td>
//                       <td>{VerifiedBook[BookDeleteIndex].book_id}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="row" >
//                   <div className="col-1 center " />
//                   <div className="col-5 center " >
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         setBookDeleteModal(false)
//                       }}
//                     >
//                       Cancel
//                     </div>
//                   </div>
//                   <div className="col-5 center">
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         VerifiedBook.splice(BookDeleteIndex, 1)
//                         // BatchExamID.splice(BatchExamDeleteIndex, 1)
//                         setBookDeleteModal(false)
//                       }}
//                     >
//                       OK
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       }

//       {AddNewBook &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setAddNewBook(false)
//                   }}
//                 >X</div>
//                 <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
//                   <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
//                     value={serachName} placeholder="Name"
//                     onChange={(event) => {
//                       setSearchName(event.target.value)
//                       setBookArray(BookArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
//                     }}
//                   />
//                 </div>

//                 <div style={{ height: "400px", overflowY: "scroll" }}>
//                   <table>
//                     <tbody>
//                       <tr>
//                         <td></td>
//                         <td>Book ID</td>
//                         <td>Book Name</td>
//                         <td>Choose</td>
//                       </tr>
//                       {BookArray.map((item, index) => (
//                         <tr key={index}>
//                           <MappingBook item={item} />
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div style={{ padding: "10px" }}>
//                   <div className="row">
//                     <div className="col-1" />
//                     <div className="col-5 center " >
//                       <div className="sign-in-button-4" style={{ width: "80%" }}
//                         onClick={() => {
//                           setVerifiedBookCache(VerifiedBook)
//                           // setBatchExamIDCache(BatchExamID)
//                           setAddNewBook(false)
//                         }}
//                       >
//                         Cancel
//                       </div>
//                     </div>
//                     <div className="col-5 center">
//                       <div className="sign-in-button-4" style={{ width: "80%" }}
//                         onClick={() => {
//                           setVerifiedBook(VerifiedBookCache)
//                           // setBatchExamID(BatchExamIDCache)
//                           setAddNewBook(false)
//                         }}
//                       >
//                         Done
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>


//             </div>
//           </div>
//         </section>
//       }



//       {
//         reload &&
//         <div className="modal" style={{ padding: "50vw" }}>
//           <div className="dot-pulse"></div>
//         </div>
//       }
//       {
//         AlertShow &&
//         <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
//       }
//     </div >
//   )

// }

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [author, setAuthor] = React.useState(FetchData.author)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)

  const [verifiedExamList, setVerifiedExamList] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list)
  )
  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [BatchExam, setBatchExam] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list))
  const [BatchExamCache, setBatchExamCache] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list))

  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [AddNew, setAddNew] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")
  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])

  const [AddNewBook, setAddNewBook] = React.useState(false)
  const [serachBookName, setSearchBookName] = React.useState("")
  const [BookArray, setBookArray] = React.useState([])
  const [BookArray1, setBookArray1] = React.useState([])
  const [VerifiedBook, setVerifiedBook] = React.useState(
    FetchData.meta.verified_book_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_book_list))
  const [VerifiedBookCache, setVerifiedBookCache] = React.useState(
    FetchData.meta.verified_book_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_book_list))
  const [BookDeleteIndex, setBookIndex] = React.useState("")
  const [BookDeleteModal, setBookDeleteModal] = React.useState(false)

  const [AddNewCourse, setAddNewCourse] = React.useState(false)
  const [serachCourseName, setSearchCourseName] = React.useState("")
  const [CourseArray, setCourseArray] = React.useState([])
  const [CourseArray1, setCourseArray1] = React.useState([])
  const [CategoryArray, setCategoryArray] = React.useState([])
  const [VerifiedCourse, setVerifiedCourse] = React.useState(
    FetchData.meta.verified_course_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_course_list))
  const [VerifiedCourseCache, setVerifiedCourseCache] = React.useState(
    FetchData.meta.verified_course_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_course_list))
  const [CourseDeleteIndex, setCourseIndex] = React.useState("")
  const [CourseDeleteModal, setCourseDeleteModal] = React.useState(false)
  const [IDCardNumber, setIDCardNumber] = React.useState(FetchData.meta.id_card_number)
  const [ClassShift, setClassShift] = React.useState(FetchData.meta.class_shift)
  const [AdmissionDate, setAdmissionDate] = React.useState(FetchData.meta.admission_date)
  const [PayedRs, setPayedRs] = React.useState(FetchData.meta.payed_rs)
  const [DueRs, setDueRs] = React.useState(FetchData.meta.due_rs)
  const [PaymentDueDate, setPaymentDueDate] = React.useState(FetchData.meta.due_payment_date)


  const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
  const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
  const [EditScreenShot, setEditScreenShot] = React.useState("")
  const [EditStartDate, setEditStartDate] = React.useState("")
  const [EditEndDate, setEditEndDate] = React.useState("")
  const [EditAmountPaid, setEditAmountPaid] = React.useState("")
  const [EditRemarks1, setEditRemarks1] = React.useState("")
  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)
  const [choose, setChoose] = React.useState(0)
  const [VerifyTime, setTime] = React.useState("")

  // const [Name, setName] = React.useState(FetchData.title.rendered)
  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [UBTC, setUBTC] = React.useState(0)

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": UserRole,
        "user_profile_picture": ProfilePicture,
        "user_subscription_start": SubscriptionStart,
        "user_subscription_end": SubscriptionEnd,
        "user_subscription_time": subscriptionTime,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "verified_exam_list": JSON.stringify(BatchExam),
        "verified_book_list": JSON.stringify(VerifiedBook),
        "verified_course_list": JSON.stringify(VerifiedCourse),
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "user_verification_history": userVerificationHistory,
        "batch_id": batchID,
        "batch_name": batchName,
        "id_card_number": `${IDCardNumber}`,
        "class_shift": `${ClassShift}`,
        "admission_date": `${AdmissionDate}`,
        "payed_rs": `${PayedRs}`,
        "due_rs": `${DueRs}`,
        "due_payment_date": `${PaymentDueDate}`

      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)

          // dispatch(login(
          //   `${Login.id}`,
          //   `${Login.userName}`,
          //   `${window.btoa(`${userName}:${Password}`)}`,
          //   `${Login.userEmail}`,
          //   `${Login.userRole}`,
          //   `${Login.userPhoto}`,
          //   `${Login.userPhoneNumber}`,
          //   `${Login.userBatch}`,
          // ))
          // AdsPUTApi(dataWithPassword, `users`, `${userID}`)
          //   .then(res => {
          //     AfterUserCreate(userID)
          //   })
          //   .catch(e =>
          //     console.log(e)
          //   )
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const dateChangePressed = (verifyTime) => {
    const date = new Date();

    const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

    const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
    const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
    setEditStartDate(`${yearToday}-${monthToday}-${day}`)
    setEditEndDate(`${year}-${month}-${day}`)

  }

  useEffect(() => {
    // BatchList()
    FetchExamList()
    FetchBookList()
    FetchCourseList()
  }, [])
  const FetchExamList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,slug,title,meta.featured_image,meta.category_name," },
      slugs.db_slug_chapters
    )
      .then((res) => {
        setCourseArray1(res.data)
        setCourseArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const FetchCourseList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,slug,title,meta.featured_image,meta.exam_type,meta.category_id,meta.exam_type,meta.exam_sub_type,meta.exam_sub_type2" },
      slugs.db_slug_paid_exam
    )
      .then((res) => {
        setExamArray1(res.data)
        setExamArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const FetchBookList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.exam_type" },
      slugs.db_slug_book
    )
      .then((res) => {
        setBookArray1(res.data)
        setBookArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExamCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    return (
      <>
        <td>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td> {item.meta.exam_sub_type} {item.meta.exam_sub_type2}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          setCheckON(true)
          if (BatchExamCache.filter(a => a.exam_id == item.id).length == 0) {
            BatchExamCache.push({
              exam_id: item.id,
              featured_image: item.meta.featured_image,
              name: item.title.rendered,
              exam_type: item.meta.exam_type,
              slug: item.slug
            })
            // BatchExamIDCache.push(item.id)
          }
        }}
        >Choose</td>
      </>
    )
  }

  const MappingBook = ({ item }) => {
    const EnrollmentSearch = Object.values(VerifiedBookCache).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      setCheckON(true)
      if (VerifiedBookCache.filter(a => a.exam_id == item.id).length == 0) {
        VerifiedBookCache.push({
          book_id: item.id,
          name: item.title.rendered,
          slug: item.slug
        })
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
      </>
    )
  }


  const MappingCourse = ({ item }) => {
    const EnrollmentSearch = Object.values(VerifiedCourseCache).filter(a => a.course_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      setCheckON(true)
      if (VerifiedCourseCache.filter(a => a.course_id == item.id).length == 0) {
        VerifiedCourseCache.push({
          course_id: item.id,
          name: item.title.rendered,
          slug: item.slug
        })
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
      </>
    )
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Student Name </td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Role</td>
                <td className="col-6">
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"teacher"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Subscription Start</td>
                <td className="col-6">
                  <input className="input-common" type="date"
                    value={SubscriptionStart} placeholder="Subscription Start"
                    onChange={(event) => { setSubscriptionStart(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Subscription End</td>
                <td className="col-6">
                  <input className="input-common" type="date"
                    value={SubscriptionEnd} placeholder="Subscription End"
                    onChange={(event) => { setSubscriptionEnd(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">User ID</td>
                <td className="col-6">
                  <input className="input-common"
                    value={userID} placeholder="User ID"
                    onChange={(event) => { setUserID(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          Change Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          {Login.userRole == "author" &&
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Student
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* {Login.userRole == "author" && */}
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Verified Book List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Book ID</td>
                <td>Book Name</td>
              </tr>
              {VerifiedBook.map((item, index) => (
                <tr key={index}>
                  <td>{item.book_id}</td>
                  <td>{item.name}</td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setBookIndex(index)
                    setBookDeleteModal(true)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNewBook(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* } */}
      {BookDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBookDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Book for the user?
                <table>
                  <tbody>
                    <tr>
                      <td>Book Name : </td>
                      <td>{VerifiedBook[BookDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{VerifiedBook[BookDeleteIndex].book_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBookDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        VerifiedBook.splice(BookDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setBookDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNewBook &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewBook(false)
                  }}
                >X</div>
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setBookArray(BookArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Book ID</td>
                        <td>Book Name</td>
                        <td>Choose</td>
                      </tr>
                      {BookArray.map((item, index) => (
                        <tr key={index}>
                          <MappingBook item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedBookCache(VerifiedBook)
                          // setBatchExamIDCache(BatchExamID)
                          setAddNewBook(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedBook(VerifiedBookCache)
                          // setBatchExamID(BatchExamIDCache)
                          setAddNewBook(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }



      {Login.userRole == "author" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Exam List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>Exam ID</td>
                  <td>Exam Name</td>
                  <td>Exam Type</td>
                </tr>

                {BatchExam.map((item, index) => (
                  <tr key={index}>
                    <td>{item.exam_id}</td>
                    <td>{item.name}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setBatchExamIndex(index)
                      setBatchExamDeleteModal(true)
                      // BatchExam.splice(index, 1)
                    }}>Delete</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setAddNew(true)
                  }}>Add New</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChoose(0)
                    setAddNew(false)
                  }}
                >X</div>
                {/* <div className="col-md-6 row">
                  <div className="col-1" />
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div> */}
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                  {/* <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
                    onClick={() => {
                      setUBTC(0)
                      setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT"))
                    }}
                  >
                    UBT
                  </div>
                  <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
                    setUBTC(1)
                    setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
                  }} >
                    ChapterWise
                  </div> */}
                </div>
             
                  <div style={{ height: "400px", overflowY: "scroll" }}>
                    <table>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>Exam ID</td>
                          <td>Exam Name</td>
                          <td>Exam Type</td>
                          <td>Choose</td>
                        </tr>
                        {ExamArray.map((item, index) => (
                          <tr key={index}>
                            <MappingExam item={item} />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          // setBatchExamIDCache(BatchExamID)
                          setChoose(0)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          // setBatchExamID(BatchExamIDCache)
                          setChoose(0)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }

      {Login.userRole == "author" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Verified Course List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>Course ID</td>
                  <td>Course Name</td>
                </tr>
                {VerifiedCourse.map((item, index) => (
                  <tr key={index}>
                    <td>{item.course_id}</td>
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setCourseIndex(index)
                      setCourseDeleteModal(true)
                    }}>Delete</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setAddNewCourse(true)
                  }}>Add New</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      {CourseDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Course for the user?
                <table>
                  <tbody>
                    <tr>
                      <td>Course Name : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Course ID : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].course_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setCourseDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        VerifiedCourse.splice(CourseDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setCourseDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNewCourse &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewCourse(false)
                  }}
                >X</div>
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachCourseName} placeholder="Name"
                    onChange={(event) => {
                      setSearchCourseName(event.target.value)
                      setCourseArray(CourseArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Course ID</td>
                        <td>Course Name</td>
                        <td>Course Category</td>
                        <td>Choose</td>
                      </tr>
                      {CourseArray.map((item, index) => (
                        <tr key={index}>
                          <MappingCourse item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourseCache(VerifiedCourse)
                          // setBatchExamIDCache(BatchExamID)
                          setAddNewCourse(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourse(VerifiedCourseCache)
                          // setBatchExamID(BatchExamIDCache)
                          setAddNewCourse(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }




      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}