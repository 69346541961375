import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import NotFound from '../../NotFound';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function StartCourse(props) {
  // const { ChapterData } = props;
  const dispatch = useDispatch()
  const LoginState = useSelector((state) => state.LoginState);
  const [ChapterData, setChapterData] = React.useState([])
  const [classDetails, setClassDetails] = React.useState({})
  const [choose, setChoose] = React.useState(0)
  const [NotFoundData, setNotFound] = React.useState(false)
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [PreviewUrl, setPreviewUrl] = React.useState("")
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const fetchExam = (perPage, verifiedExamList) => {
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_chapters)
      .then((res) => {
        // setAllExamList(res.data)
        // const examList = res.data
        // var verified = [];
        // var unverified = [];
        // for (let i = 0; i < examList.length; i++) {
        //   if (verifiedExamList.filter(a => a.course_id == examList[i].id).length !== 0)
        //     verified.push(examList[i])
        //   else
        //     unverified.push(examList[i])

        // }
        console.log(res.data)
        if (res.data.length !== 0)
          setClassDetails(res.data[0])
        setChoose(1)
        // setChapterData(Object.values(JSON.parse(res.data[0].meta.course_content)))
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
      .finally(() => {
        setExamFetch(true)
      })
  }
  const location = useLocation()
  useEffect(() => {
    var type;
    type = location.pathname.slice(slugs.start_course.length + 1)
    AdsGETApiAuth({
      status: "any",
      "filter[meta_query][1][key]": "user_id",
      "filter[meta_query][1][value][0]": LoginState.userID,
      "filter[meta_query][1][compare]": "=",
      _fields: "meta"
    }, "user_data")
      .then((response) => {
        if (response.data.length !== 0) {
          // setUsersData(response.data[0])
          var verifiedExamListCache = response.data[0].meta.verified_course_list
          var verifiedExamList;
          if (verifiedExamListCache == "")
            verifiedExamList = [];
          else
            verifiedExamList = JSON.parse(verifiedExamListCache)
          console.log(verifiedExamList, type)
          if (verifiedExamListCache.includes(type))
            fetchExam({
              slug: type
            },
              verifiedExamList
            )
          else {
            setNotFound(true)
            setExamFetch(true)
          }
        }


      })
      .catch(e => {
        if (e.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }, [])

  return (
    <div style={{ margin: "3px" }}>
      {ExamFetch &&
        <>
          {NotFoundData ?
            <>
              <NotFound />
            </>

            :
            <>
              {choose == 0 &&
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10 form-view mt-3">
                    <div className="row" style={{ margin: "10px" }}>
                      <h2>Classes</h2>
                      <div className="row" style={{ marginLeft: "10px" }}>
                        {ChapterData.map((item, index) => (
                          <div key={index} className="col-sm-6 col-12">
                            <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                            >
                              <h4>{item.chapter_number} {item.title.rendered}</h4>
                              <div className="row">
                                <div className="col-4" style={{ padding: 10 }}
                                  onClick={() => {
                                    setClassDetails(item)
                                    setChoose(1)
                                  }}
                                >
                                  <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> View Class</span>
                                </div>
                                {item.meta.class_link !== "" &&
                                  <div className="col-4" style={{ padding: 10 }}>
                                    <a className="Link" href={item.meta.class_link} target="_blank">
                                      <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> Join Live</span>
                                    </a>
                                  </div>
                                }
                                {item.meta.whatsapp_group !== "" &&
                                  <div className="col-4" style={{ padding: 10 }}>
                                    <a className="Link" href={item.meta.whatsapp_group} target="_blank">
                                      <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}>Whatsapp</span>
                                    </a>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {choose == 1 &&
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10 form-view mt-3">
                    <div className="row" style={{ margin: "10px" }}>
                      <h2>{classDetails.title.rendered}</h2>
                      {/* <span style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => {
                          setChoose(0)
                        }}
                      >
                        All Class
                      </span> */}
                      {classDetails.meta.class_link !== "" &&
                        <div className="col-4" style={{ padding: 10 }}>
                          <a className="Link" href={classDetails.meta.class_link} target="_blank">
                            <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> Join Live</span>
                          </a>
                        </div>
                      }
                      {classDetails.meta.whatsapp_group !== "" &&
                        <div className="col-4" style={{ padding: 10 }}>
                          <a className="Link" href={classDetails.meta.whatsapp_group} target="_blank">
                            <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}>Whatsapp</span>
                          </a>
                        </div>
                      }
                      <div className="row" style={{ marginLeft: "10px" }}>
                        {Object.values(JSON.parse(classDetails.meta.course_content)).map((item2, index2) => (
                          // {ChapterData.map((item, index) => (
                          <div key={index2} className="col-sm-4 col-12">
                            <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                              onClick={() => {

                                setPreviewPressed(true)
                                // setPreviewObj(item)
                                // setPreviewType(item.chapter_type)
                                // if (item.chapter_type == "Video") {
                                if (item2.youtube_id == "")
                                  setPreviewUrl(item2.video_link)
                                else
                                  setPreviewUrl(`https://www.youtube.com/embed/${item2.youtube_id}`)
                                // }
                                // else if (item.chapter_type == "PDF")
                                //   setPreviewUrl(item.pdf_url)
                                // else
                                //   setPreviewUrl(item.content)
                                // setClassDetails(item2)
                              }}
                            >
                              <h4>{item2.chapter_number}. {item2.title}</h4>
                              <div>{item2.description}</div>
                              <div style={{ padding: 10 }}>
                                <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> View</span>
                              </div>
                              <div style={{ marginLeft: "40px", fontSize: "12px" }}>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {PreviewPressed &&
                <div className="modal">
                  <div className="row">
                    <div className="col-1" />
                    <div class="modal-dialog" role="document" className="col-10">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            onClick={() => { setPreviewPressed(false) }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div style={{ minHeight: "78vh", overflowY: "scroll" }}>
                            <iframe
                              style={{ width: "100%", height: "70vh" }}
                              src={PreviewUrl
                                // PreviewObj.youtube_id == "" ? PreviewObj.video_link :
                                //   `https://www.youtube.com/embed/${PreviewObj.youtube_id}`
                              }>
                            </iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          }
        </>
      }
    </div >
  )
}

