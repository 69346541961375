import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
// import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "./PDFGroups";
import ImageUpload from "../../../ImageUpload";
import AudioUpload2 from "./AudioUpload2";
import FileUploadChapters from "./PDFGroups";

const AlertComponent = ({
  setAlertShow,
  Alertcomp
}) => {
  return (
    <div className="modal"
      onClick={() => {
        setAlertShow(false)
        window.location.reload(false)
      }}
    >
      <div>
        <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
          <div className="col-lg-4 col-1"></div>
          <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
            <div className="p-5">
              <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
              <div className="sign-in-button-1 col-8">
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [PDf, setPDF] = React.useState(FetchData.meta.book_pdf)
  const [Author, setAuthor] = React.useState(FetchData.meta.book_author)
  const [PublishedDate, setPublishedDate] = React.useState(FetchData.meta.book_published_date)
  const [AboutBook, setAboutBook] = React.useState(FetchData.meta.about_book)
  const [Type, setType] = React.useState(FetchData.meta.type)

  const [reload, setReload] = React.useState(false)
  const [Free, setFree] = React.useState(FetchData.meta.free)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [CourseContent, setCourseContent] = React.useState(JSON.parse(FetchData.meta.course_content))
  const [CourseContentEdit, setCourseContentEdit] = React.useState(false)
  const [CourseEditData, setCourseEditData] = React.useState({})
  const [CourseEditKey, setCourseEditKey] = React.useState("")
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [OtherInformation, setOtherInformation] = React.useState(JSON.parse(FetchData.meta.chapter_other_informations))
  const [OtherInformationModal, setOtherInformationModal] = React.useState(false)
  const [ClassLink, setClassLink] = React.useState(FetchData.meta.class_link)
  // const [Batches, setBatches] = React.useState(JSON.parse(FetchData.meta.batches))

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "free": Free,
        "chapter_other_informations": JSON.stringify(OtherInformation),
        "course_content": JSON.stringify(CourseContent),
        "featured_image": FeaturedImage,
        "class_link": ClassLink
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_chapters)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.course_packages_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_chapters, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }


  const [BatchList, setBatchList] = React.useState([])
  const [AddNew, setAddNew] = React.useState(false)
  const [EditExamData, setEditExamData] = React.useState({})
  const [ExamArray, setExamArray] = React.useState({})
  const [ExamArray1, setExamArray1] = React.useState({})

  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Course Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Chapter Other Information</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setOtherInformationModal(true)
                    }}
                  >
                    {JSON.stringify(OtherInformation) !== "[]" && "Contains Book Information"}
                    <span className={"passive-option-2"}>
                      Edit Book Information
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td className="col-3">Class Link</td>
                <td className="col-6">
                  <input className="input-common-4"
                    value={ClassLink} placeholder="Class Link"
                    onChange={(event) => { setClassLink(event.target.value) }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Course
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Chapter Content</center></h4>
          <div>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    setCourseEditData({
                      "chapter_number": `${Object.keys(CourseContent).length + 1}`,
                      "title": "",
                      "description": "",
                      "is_preview": "",
                      "chapter_type": "Video",
                      "video_link": "",
                      "youtube_id": "",
                      "content": "",
                      "time": "",
                      "pdf_url": "",
                      "pdf_list": "[]",
                      "audio_list": "[]",
                    })
                    setCourseEditKey(`item-${Object.keys(CourseContent).length}`)

                    setCourseContentEdit(true)
                  }}
                >
                  Add Content
                </div>
              </div>
            </div>
            <div>
              <div className="row m-4">
                <div>
                  <div className="row">
                    {Object.values(CourseContent).map((item, index) => {
                      return (
                        <div key={index} className="col-6">
                          <div
                            style={{
                              margin: "5px", padding: "5px 10px",
                              border: "2px solid black",
                              borderRadius: "10px",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setCourseEditData(item)
                              setCourseEditKey(Object.keys(CourseContent)[index])
                              setCourseContentEdit(true)

                            }}
                          >
                            <div style={{ fontWeight: "bold", }}>
                              {item.title}
                            </div>

                            <div style={{}}>
                              {item.description}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }

      {
        CourseContentEdit &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseContentEdit(false)
                    setCourseEditData([])
                    setCourseEditKey("")
                  }}
                >X</div>

                <CourseUploadComp
                  oneChapterData={CourseEditData} setCourseEditData={setCourseEditData}
                  keyValue={CourseEditKey} setCourseEditKey={setCourseEditKey}
                  CourseContent={CourseContent} setCourseContent={setCourseContent}
                  setCourseContentEdit={setCourseContentEdit}
                  CourseType={"Book"}
                />
              </div>
            </div>
          </div>
        </section>
      }
      {OtherInformationModal &&
        <section className="modal" >
          <BookInformationComponent
            setChapterWisePDF={setOtherInformationModal}
            ChapterWisePDF={OtherInformationModal}
            PDfGroup={OtherInformation}
            setPDFGroup={setOtherInformation}
          />
        </section>
      }
    </div>
  )

}



const CourseUploadComp = ({
  oneChapterData, setCourseEditData,
  keyValue, setCourseEditKey, setCourseContentEdit,
  CourseContent, setCourseContent,
  CourseType
}) => {
  const [ChapterNumber, setChapterNumber] = React.useState(oneChapterData.chapter_number)
  const [Title, setTitle] = React.useState(oneChapterData.title)
  const [Description, setDescrition] = React.useState(oneChapterData.description)
  const [Time, setTime] = React.useState(oneChapterData.time)
  const [VideoLink, setVideoLink] = React.useState(oneChapterData.video_link)
  const [YoutubeID, setYoutubeID] = React.useState(oneChapterData.youtube_id)

  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined)
      setYoutubeID(url[2].split(/[^0-9a-z_\-]/i)[0])
    // console.log(url[2].split(/[^0-9a-z_\-]/i)[0])
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  return (
    <div>
      <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <table>
          <tbody>
            <tr>
              <td className="col-3">Day Number</td>
              <td className="col-6">
                {/* {ChapterNumber} */}
                <input className="input-common-4"
                  value={ChapterNumber} placeholder="Number"
                  onChange={(event) => { setChapterNumber(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Title</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Title} placeholder="Chapter Title"
                  onChange={(event) => { setTitle(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Description</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Description} placeholder="Description"
                  onChange={(event) => { setDescrition(event.target.value) }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Time</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={Time} placeholder="Time"
                  onChange={(event) => { setTime(event.target.value) }}
                />
              </td>
            </tr>
            {/* <tr>
              <td className="col-3">Free/Pro</td>
              <td className="col-6">
                <CustomButton setData={setIsPreview} Data={IsPreview} DataValue={"Free"} />
                <CustomButton setData={setIsPreview} Data={IsPreview} DataValue={"Pro"} />
              </td>
            </tr> */}
            {/* <tr>
              <td className="col-3">Type</td>
              <td className="col-6">
                <CustomButton setData={setChapterType} Data={ChapterType} DataValue={"Video"} />
                <CustomButton setData={setChapterType} Data={ChapterType} DataValue={"Text"} />
                <CustomButton setData={setChapterType} Data={ChapterType} DataValue={"PDF"} />
              </td>
            </tr> */}
            <tr>
              <td className="col-3">Video Link</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={VideoLink} placeholder="Video Link"
                  onChange={(event) => {
                    setVideoLink(event.target.value)
                    YouTubeGetID(event.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="col-3">Youtube ID</td>
              <td className="col-6">
                <input className="input-common-4"
                  value={YoutubeID} placeholder="Youtube ID"
                  onChange={(event) => { setYoutubeID(event.target.value) }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <center>
        <div style={{ width: "30%" }} className="sign-in-button-1"
          onClick={() => {
            setCourseContent({
              ...CourseContent,
              [keyValue]: {
                "chapter_number": `${ChapterNumber}`,
                "title": `${Title}`,
                "time": `${Time}`,
                "description": `${Description}`,
                "video_link": `${VideoLink}`,
                "youtube_id": `${YoutubeID}`,
              }
            })
            setCourseEditKey(``)
            setCourseContentEdit(false)
          }}
        >Done</div>
      </center>
    </div >
  )
}



const BookInformationComponent = ({
  setChapterWisePDF,
  ChapterWisePDF,
  PDfGroup,
  setPDFGroup,
}) => {
  const [numberofChapters, setNumberofChapters] = React.useState("")
  const [Title, setTitle] = React.useState("")
  const [Description, setDescription] = React.useState("")

  return (
    <div className="row">
      <div className="col-md-3 col-1"></div>
      <div className="col-md-6 col-10  shadow bg-white">

        <div style={{ border: "1px solid #999", padding: "10px", margin: "10px" }}>
          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
            onClick={() => {
              setChapterWisePDF(false)
            }}
          >X</div>
          <div style={{ margin: " 10px", overflowX: 'hidden', padding: "9px", border: "1px solid #aaa" }}>
            <div style={{ maxHeight: "300px", }}>
              <table>
                <tbody>
                  {Object.values(PDfGroup).map((item, index) => (
                    <tr key={index}
                      style={{ cursor: "pointer", padding: "5px 0" }}
                    >
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.title} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "title": `${event.target.value}`,
                                "description": `${item.description}`,
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td>
                        <textarea rows={3} className="input-common" style={{ width: "100%" }}
                          value={item.description} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "description": `${event.target.value}`,
                                "title": `${item.title}`,
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
                  <tr style={{ cursor: "pointer", padding: "5px 0" }}>
                    <td>{Object.keys(PDfGroup).length + 1}</td>
                    <td>
                      <input className="input-common" style={{ width: "100%" }}
                        value={Title} placeholder="Title"
                        onChange={(event) => {
                          setTitle(event.target.value)
                        }}
                      />
                    </td>
                    <td>
                      <textarea rows={3} className="input-common" style={{ width: "100%" }}
                        value={Description} placeholder="Description"
                        onChange={(event) => {
                          setDescription(event.target.value)
                        }}
                      />
                    </td>
                    <td
                      onClick={() => {
                        // setNumberofChapters(event.target.value)
                        var pdf = PDfGroup;
                        pdf = {
                          ...pdf,
                          [`item-${Object.keys(PDfGroup).length}`]: {
                            "title": `${Title}`,
                            "description": `${Description}`,
                          }
                        }
                        setPDFGroup(pdf)
                        setTitle("")
                        setDescription("")
                      }}
                    >Add</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  setChapterWisePDF(false)
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}