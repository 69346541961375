import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../Constant/slugs';
import '../styles.css'
import { MainUrl, sideBarImage } from '../Constant/api';
import Database from '../Constant/database.json'

const SideBarBlock = ({
  allSlug, newSlug, location,
  Title, icon, setExpand, Expand,
  setMenuExpand
}) => {
  return (
    <div>
      <div className={location.pathname == allSlug ? "row active-sidebar" : "row passive-sidebar"}

      >
        <Link className={"Link col-10"} to={allSlug}>
          <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}
            onClick={() => {
              setMenuExpand(false)
            }}
          >
            &nbsp; &nbsp;{Title}
          </div>
        </Link>
        {newSlug !== "" &&
          <div className="col-1" style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpand(!Expand)
            }}>
            <i className={Expand ? "fa fa-minus" : "fa fa-plus"} style={{ fontSize: "10px" }} />
          </div>
        }
      </div>
      {Expand && newSlug !== "" &&
        <>
          <Link className={location.pathname == allSlug ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={allSlug}
          >
            <div className={location.pathname == allSlug ? "Link active-sidebar-font" : "Link passive-sidebar-font"}>
              All {Title}
            </div>
          </Link>
          <Link className={location.pathname == `/${newSlug}` ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={newSlug}
          >
            <div className={location.pathname == `/newSlug` ? "active-sidebar-font" : "passive-sidebar-font"}>
              Add New {Title.slice(0, -1)}
            </div>
          </Link>
        </>
      }
    </div>
  )
}
export default function SideBar({ setMenuExpand }) {
  const LoginUser = useSelector((state) => state.LoginState);
  const [Menu, setMenu] = React.useState(false)
  const location = useLocation()

  const [DemoExamExpand, setDemoExamExpand] = React.useState(false)
  const [ExamExpand, setExamExpand] = React.useState(false)
  const [ChapterExamExpand, setChapterExamExpand] = React.useState(false)
  const [QBExamExpand, setQBExamExpand] = React.useState(false)
  const [WeeklyExamExpand, setWeeklyExamExpand] = React.useState(false)
  const [GrammerExamExpand, setGrammerExamExpand] = React.useState(false)
  const [BookExpand, setBookExpand] = React.useState(false)
  const [StudentExpand, setStudentExpand] = React.useState(false)
  const [TeacherExpand, setTeacherExpand] = React.useState(false)
  const [PackageExpand, setPackageExpand] = React.useState(false)
  const [BatchOrdersExpand, setBatchOrdersExpand] = React.useState(false)


  const type_array = {
    "ubt": "ubt",
    "chapter_exam": "chapter-exam",
    "question_bank": "question-bank",
    "weekly_test": "weekly-test",
    "grammer_test": "grammer-test"
  }
  return (
    <div>
      <div className="small-display-none">
        <div style={{justifyContent: 'center', display: 'flex', padding: "30px", borderBottom: "1px solid #eee" }}>
          <img
            src={sideBarImage}
            style={{ width: "200px", height: "100px", objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="small-display-block">
        <div className="row passive-sidebar" onClick={() => { setMenuExpand(false) }}>
          <div className={"passive-sidebar-font"}>
            <i className="fa fa-close" style={{ fontSize: "16px" }} />&nbsp; &nbsp;Close
          </div>
        </div>
      </div>
      {/* Home */}
      {/* {LoginUser.userRole} */}
      {LoginUser.userRole == "author" &&
        <>
          <SideBarBlock
            allSlug={slugs.all_teachers} newSlug={slugs.new_student}
            location={location} Title={"Teachers"}
            icon={""}
            setExpand={setTeacherExpand} Expand={TeacherExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_students} newSlug={slugs.new_student}
            location={location} Title={"Student"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
          />
          {/* <SideBarBlock
            allSlug={slugs.all_course_packages} newSlug={slugs.new_course_package}
            location={location} Title={"Courses"}
            icon={""}
            setExpand={setDemoExamExpand}
            Expand={DemoExamExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={slugs.all_demo_exam} newSlug={slugs.new_demo_exam}
            location={location} Title={"Free Exams"}
            icon={""}
            setExpand={setDemoExamExpand}
            Expand={DemoExamExpand}
          /> */}
          <SideBarBlock
            allSlug={`${slugs.all_exam}/${type_array.ubt}`} newSlug={`${slugs.new_exam}/${type_array.ubt}`}
            location={location} Title={"UBT Exams"}
            icon={""}
            Expand={ExamExpand}
            setExpand={setExamExpand}
          />
          {/* <SideBarBlock
            allSlug={`${slugs.all_exam}/${type_array.chapter_exam}`} newSlug={`${slugs.new_exam}/${type_array.chapter_exam}`}
            location={location} Title={"Chapter Exams"}
            icon={""}
            Expand={ChapterExamExpand}
            setExpand={setChapterExamExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={`${slugs.all_exam}/${type_array.question_bank}`} newSlug={`${slugs.new_exam}/${type_array.question_bank}`}
            location={location} Title={"Question Bank"}
            icon={""}
            Expand={QBExamExpand}
            setExpand={setQBExamExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={`${slugs.all_exam_2}/${type_array.weekly_test}`} newSlug={`${slugs.new_exam}/${type_array.weekly_test}`}
            location={location} Title={"Weekly Test"}
            icon={""}
            Expand={WeeklyExamExpand}
            setExpand={setWeeklyExamExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={`${slugs.all_exam_2}/${type_array.grammer_test}`} newSlug={`${slugs.new_exam}/${type_array.grammer_test}`}
            location={location} Title={"Grammer Test"}
            icon={""}
            Expand={GrammerExamExpand}
            setExpand={setGrammerExamExpand}
          /> */}

          <SideBarBlock
            allSlug={slugs.all_books} newSlug={slugs.new_book}
            location={location} Title={"All Books"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
          />
            {/* <SideBarBlock
            allSlug={slugs.all_books} newSlug={slugs.new_book}
            location={location} Title={"All Books"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={slugs.all_batch} newSlug={slugs.new_batch}
            location={location} Title={"All Packages"}
            icon={""}
            setExpand={setChapterExpand} Expand={ChapterExpand}
          /> */}
          <SideBarBlock
            allSlug={"/Images"} newSlug={""}
            location={location} Title={"Images"}
            icon={""}
            Expand={DemoExamExpand}
            setExpand={setDemoExamExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_daily_results} newSlug={""}
            location={location} Title={"Batch Results"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
          />
          {/* <SideBarBlock
            allSlug={slugs.all_results} newSlug={""}
            location={location} Title={"Exam Results"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
          /> */}
          {/* <SideBarBlock
            allSlug={slugs.all_results} newSlug={""}
            location={location} Title={"Code Exam Results"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
          /> */}
          <SideBarBlock
            allSlug={slugs.all_exam_packages} newSlug={slugs.new_exam_package}
            location={location} Title={"Exam Packages"}
            icon={""}
            setExpand={setPackageExpand} Expand={PackageExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_batch_orders} newSlug={slugs.new_batch_order}
            location={location} Title={"Package Orders"}
            icon={""}
            setExpand={setBatchOrdersExpand} Expand={BatchOrdersExpand}
          />

          <div className="row active-sidebar">
            Student Panel
          </div>
        </>
      }
      {/* <div className={"row passive-sidebar"}>
        <a className={"Link col-10"} href={MainUrl}>
          <div className={"passive-sidebar-font"}
            onClick={() => {
              setMenuExpand(false)
            }}
          >
            &nbsp; &nbsp;Home
          </div>
        </a>
      </div> */}
      <SideBarBlock
        allSlug={"/classroom"} newSlug={""}
        location={location} Title={"ClassTest"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
        <SideBarBlock
        allSlug={slugs.course} newSlug={""}
        location={location} Title={"Course"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.demo_exam} newSlug={""}
        location={location} Title={"Free Exam"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={`${slugs.exam}/${type_array.ubt}`} newSlug={""}
        location={location} Title={"All Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      />
      {/* <SideBarBlock
        allSlug={`${slugs.exam}/${type_array.chapter_exam}`} newSlug={""}
        location={location} Title={"Chapter Exam"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      /> */}
      {/* <SideBarBlock
        allSlug={`${slugs.weekly_exam}/${type_array.grammer_test}`} newSlug={""}
        location={location} Title={"Grammer Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      /> */}
      {/* <SideBarBlock
        allSlug={`${slugs.weekly_exam}/${type_array.weekly_test}`} newSlug={""}
        location={location} Title={"Weekly Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      /> */}
      {/* <SideBarBlock
        allSlug={`${slugs.exam}/${type_array.question_bank}`} newSlug={""}
        location={location} Title={"Question Bank Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      /> */}
      <SideBarBlock
        allSlug={slugs.daily_exam} newSlug={""}
        location={location} Title={"Exam Packages"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
      />
      <SideBarBlock
        allSlug={"/profile"} newSlug={""}
        location={location} Title={"Profile"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
    </div>
  )
}