import React from "react";

import Database from '../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import FormComponent from "../Exam/Components/Form";
import ExamData from '../Exam/Components/examData.json'
import { useLocation } from "react-router-dom/cjs/react-router-dom";
export default function NewWeeklyExam() {
  // const FetchData = Database.weekly_free_exam;
  const typeArray = {
    "ubt": "UBT",
    "chapter-exam": "Chapter",
    "question-bank": "Question Bank",
    "weekly-test": "Weekly Test",
    "grammer-test": "Grammer Test"
  }
  const location = useLocation()
  var type = location.pathname.slice(slugs.new_exam_2.length + 1) == "" ? "weekly-test" : location.pathname.slice(slugs.new_exam_2.length + 1)

  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_block_text": "[]",
      "exam_time": "",
      "exam_details": "",
      "available_unavailable": "Unavailable",
      "featured_image": "",
      "exam_type": typeArray[type],
      "exam_code":""
    }
  };
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Test</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_exam_2}>All Test</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
        db_slug={slugs.db_slug_exam_2}
        webSlug={slugs.exam_2_details}
      />
    </div>
  )
}